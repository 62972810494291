//table列配置
export const getColumns = () => {
  const columns = [
    {
      width: 100,
      type: "checkbox",
      field: "$$id",
      fixed: "left",
      align: "left",
      title: "序号",
      hidden: true
    },
    { width: 80, type: "seq", title: "序号" },
    {
      title: "订单ID",
      dataIndex: "id",
      minWidth: 140
    },
    {
      title: "运单号ID",
      dataIndex: "external_id",
      minWidth: 120
    },
    {
      title: "付款账号",
      dataIndex: "card_no",
      minWidth: 120
    },
    {
      title: "账户",
      dataIndex: "account_type_desc",
      minWidth: 120
    },
    {
      title: "服务项",
      dataIndex: "service_group_desc",
      minWidth: 120
    },
    {
      title: "金额(元)",
      field: "money",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "时间",
      field: "create_time",
      minWidth: 120,
      cellRender: "p-date"
    }
  ];
  return columns;
};
