import { formatToDateTime } from "@wlhy-web-lib/shared";
//table列配置
export const getColumns = () => {
  const columns = [
    {
      width: 100,
      type: "checkbox",
      field: "$$id",
      fixed: "left",
      align: "left",
      title: "序号",
      hidden: true
    },
    { width: 80, type: "seq", title: "序号" },
    {
      title: "车牌号",
      minWidth: 120,
      customRender: (_, row) => {
        return <span>{row.ext_json?.plate_number || "-"}</span>;
      }
    },
    {
      title: "调用时间",
      field: "create_time",
      minWidth: 120,
      cellRender: "p-date-time"
    },
    {
      title: "扣费金额(元)",
      field: "money",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "查看轨迹时间",
      minWidth: 120,
      customRender: (_, row) => {
        return (
          <span>
            {row.ext_json?.gps_start_date
              ? formatToDateTime(row.ext_json?.gps_start_date, "yyyy.MM.dd") +
                "-" +
                formatToDateTime(row.ext_json?.gps_end_date, "yyyy.MM.dd")
              : "-"}
          </span>
        );
      }
    },
    {
      title: "服务项",
      dataIndex: "service_code_desc",
      minWidth: 120
    },
    {
      title: "扣款账户",
      dataIndex: "card_no",
      minWidth: 120
    }
  ];
  return columns;
};
