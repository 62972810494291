import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal");

// 收支方向
export const useEnumAccountDirectionApi = basicApi({
  url: "/account/enum/direction",
  withCredentials: true
});

// 服务
export const useEnumServiceApi = basicApi({
  url: "/account/enum/account_service",
  withCredentials: true
});

// 账户类型
export const useEnumAccountTypeApi = basicApi({
  url: "/account/enum/account_type",
  withCredentials: true
});

// 企业列表枚举
export const useCompanyEnumApi = basicApi({
  url: "/company/list",
  withCredentials: true
});

// 数据字典枚举单个数据
export const useDictEnumApi = basicApi({
  url: "/common/enum/dict",
  withCredentials: true
});

// 服务项查询
export const useServiceEnumApi = basicApi({
  url: "/common/enum/selectors",
  withCredentials: true
});
