/**
 * 搜索头
 */
export const searchFileds = [
  {
    type: "input",
    field: "external_id",
    label: "运单号",
    iw: 160,
    props: {
      placeholder: "请输入运单号"
    }
  },
  {
    field: ["start_time", "end_time"],
    label: "扣费时间",
    type: "dateRange",
    props: {
      placeholder: ["开始时间", "结束时间"],
      dateFormat: "YYYY/MM/DD"
    },
    iw: 320
  },
  {
    type: "select",
    field: "account_type",
    label: "账户类型",
    options: [
      { value: "GENERIC", label: "通用账户" },
      { value: "DEPOSIT", label: "储值账户" },
      { value: "CONSUMER", label: "消费卡" }
    ],
    iw: 200
  }
];
