/**
 * 搜索头
 */
export const searchFileds = [
  {
    type: "input",
    field: "plate_number",
    label: "车牌号",
    iw: 160,
    props: {
      placeholder: "请输入车牌号"
    }
  },
  {
    field: ["start_time", "end_time"],
    iw: 324,
    label: "调用时间",
    type: "dateRange",
    props: {
      dateFormat: "YYYY/MM/DD"
    }
  },
  {
    type: "select",
    field: "service_code",
    label: "服务项",
    iw: 160,
    defaultValue: null,
    options: [
      {
        label: "轨迹",
        value: "VET"
      },
      {
        label: "车辆位置",
        value: "VELL"
      }
    ]
  }
];
