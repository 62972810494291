<template>
  <page-layout>
    <!-- 搜索头 -->
    <search-header
      class="mt-24"
      :schemaData="fields"
      @getDataList="handleFormSearch"
    />
    <!-- 列表 -->
    <div class="mx-24 mt-12 bg-white">
      <div
        class="px-24 py-16"
        style="border:1px solid #f3f4f6;border-bottom:1px solid #f3f4f6;"
      >
        <a-button @click="exportVoucher" class="d-flex align-center">
          <Icon name="icondaochu" width="15" height="15"></Icon>
          <span class="ml-8">导出</span>
        </a-button>
      </div>

      <p-table
        dataKey="modal"
        rowKey="id"
        :dataSource="dataSource"
        :columns="tableColumns"
      ></p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { getColumns } from "./constants/table-config";
import { useAccountLogApi, useAccountLogExportApi } from "@/apis/order";
import useSearchForm from "@/hooks/UseSearchForm";

export default defineComponent({
  name: "service-order",
  setup() {
    const route = useRoute();
    const routeSearch = ref(true);
    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());
    const fields = searchFileds.map(item => {
      return {
        ...item,
        defaultValue: item.field === "account_type" ? route.query?.type : ""
      };
    });
    console.log(fields);

    const { filter, handleFormSearch } = useSearchForm(fields);

    const accountLog = useAccountLogApi();
    const accountLogExport = useAccountLogExportApi();

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        let data = {};
        if (!routeSearch.value) {
          data = {
            ...filter.value,
            size: tableDataSource.meta.pageSize,
            page: tableDataSource.meta.currentPageNo,
            service_group: "DATA_CHECK"
          };
        } else {
          data = {
            account_id: route.query.id,
            end_time: route.query.time,
            start_time: route.query.time,
            account_type: route.query.type,
            size: tableDataSource.meta.pageSize,
            page: tableDataSource.meta.currentPageNo,
            service_group: "DATA_CHECK"
          };
          routeSearch.value = false;
        }
        const res = await accountLog(data);
        return { records: res.list, total: res.total };
      }
    });

    // 导出数据
    const exportVoucher = async () => {
      // TODO  导出数据
      const data = {
        ...filter.value,
        export_flag: true,
        size: tableDataSource.meta.pageSize,
        page: tableDataSource.meta.currentPageNo,
        service_group: "DATA_CHECK"
      };
      const res = await accountLogExport(data);
      if (res) {
        message.success("导出成功，请前往下载中心查看");
      }
    };

    return {
      routeSearch,
      tableColumns,
      fields,
      exportVoucher,
      handleFormSearch,
      getConsultingList,
      dataSource: tableDataSource
    };
  }
});
</script>
