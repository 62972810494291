/**
 * 搜索头
 */
export const searchFileds = [
  {
    field: "external_id",
    label: "运单号",
    type: "input",
    iw: 200
  },
  {
    type: "input",
    field: "card_no",
    label: "付款账号",
    iw: 200
  },
  {
    type: "select",
    field: "account_type",
    label: "账户",
    options: [
      { value: "GENERIC", label: "通用账户" },
      { value: "DEPOSIT", label: "储值账户" },
      { value: "CONSUMER", label: "消费卡" }
    ],
    iw: 200
  },
  {
    field: ["start_time", "end_time"],
    label: "时间",
    type: "dateRange",
    props: {
      placeholder: "请选择时间",
      dateFormat: "YYYY/MM/DD"
    },
    iw: 320
  }
];
