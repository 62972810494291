import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");

// 服务订单列表
export const useAccountLogApi = basicApi({
  url: "/account/account_log/query",
  withCredentials: true
});

//服务订单管理导出
export const useAccountLogExportApi = basicApi({
  url: "/account/account_log/export",
  withCredentials: true
});
