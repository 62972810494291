//table列配置
export const getColumns = () => {
  const columns = [
    {
      width: 100,
      type: "checkbox",
      field: "$$id",
      fixed: "left",
      align: "left",
      title: "序号",
      hidden: true
    },
    { width: 80, type: "seq", title: "序号" },
    {
      title: "订单ID",
      dataIndex: "id",
      minWidth: 140
    },
    {
      title: "运单号",
      dataIndex: "external_id",
      minWidth: 120
    },
    {
      title: "客户运费(元)",
      field: "fee",
      minWidth: 120,
      cellRender: "p-money",
      customRender: (_, row) => {
        const fee = row.ext_json?.fee;
        return <span>{fee ? (fee / 100).toFixed(2) : "-"}</span>;
      }
    },
    {
      title: "服务费用(元)",
      field: "money",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "动账时间",
      field: "create_time",
      minWidth: 120,
      cellRender: "p-date-time"
    },
    {
      title: "账号",
      dataIndex: "card_no",
      minWidth: 120
    },
    {
      title: "账户类型",
      dataIndex: "account_type_desc",
      minWidth: 120
    }
  ];
  return columns;
};
