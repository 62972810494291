<template>
  <div id="serviceOrderBox">
    <Tabs v-model:activeKey="activeKey" :animated="false" :tabBarGutter="48">
      <TabPane key="DATA_CHECK" tab="数据自检">
        <DATACHECK v-if="activeKey === 'DATA_CHECK'" />
      </TabPane>
      <TabPane key="GPS" tab="轨迹">
        <GPS v-if="activeKey === 'GPS'" />
      </TabPane>
      <TabPane key="ETC" tab="ETC">
        <ETC v-if="activeKey === 'ETC'" />
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { Tabs } from "ant-design-vue";
import DATACHECK from "./components/DATACHECK/index";
import GPS from "./components/GPS/index";
import ETC from "./components/ETC/index";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "service-invoice",
  components: { Tabs, TabPane: Tabs.TabPane, DATACHECK, GPS, ETC },
  setup() {
    const route = useRoute();
    const activeKey = ref("DATA_CHECK");
    if (
      (route.query.tab && route.query.tab === "DATA_CHECK") ||
      route.query.tab === "GPS" ||
      route.query.tab === "ETC"
    ) {
      activeKey.value = route.query.tab;
    }
    return {
      activeKey
    };
  }
});
</script>

<style lang="less">
#serviceOrderBox {
  .ant-tabs-bar {
    margin: 0;
  }
  .ant-tabs-nav-scroll {
    background: #fff;
    margin-bottom: 2px;
  }
  .ant-tabs-tab {
    color: #808080;
    padding: 12px 0;
    &:nth-child(1) {
      margin-left: 40px;
    }
  }
  .ant-tabs-tab-active {
    color: #007aff;
  }
}
</style>
